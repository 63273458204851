.phoneNavItemWarp {
  min-height: 40px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: Inter, Inter;
  line-height: 1.2;
  color: var(--app-text-color);
  font-size: 18px;
  padding: 5px 0;
}

.navItemWarp {
  cursor: pointer;
  padding: 5px 1rem;
  // padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // background: var(--foreground-rgb);

  :global {
    .anticon {
      margin-left: 6px;
    }
  }

  .navItemTextDiv {
    flex: 1;
    display: flex;

    .navItemText {
      flex: 1;
      // font-size: 18px;
      font-family: Inter, Inter;
      line-height: 1.2;
      color: var(--app-text-color);

      .tipList {
        .tipItem {
          display: flex;
          padding: 9px;
          align-items: center;
          border-radius: 5px;
          cursor: pointer;

          &:hover {
            background: #f6f6f6;
          }

          .icon {
            width: 22px;
            height: 22px;
            background: #eeebff;
            border-radius: 100%;
            margin-right: 14px;
          }
        }
      }

      :global {
        .next-antd-tooltip-inner {
          width: 287px;
          color: #000;
          padding: 11px 12px;
          border-radius: 10px;
        }
      }
    }
  }

  .navItemMobileText {
    font-family: Inter, Inter;
    line-height: 1.2;
    color: var(--app-text-color);
    font-size: 18px;
    font-weight: 500;
    padding: 5px 0;

    .toolkitMbile {
      width: 100%;

      .toolkitContent {
        .isMobileToolkit {
          padding: 10px 0 10px 20px;

          &:first-child {
            padding-top: 20px;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .navDropdown {
    z-index: 1;
    background: var(--foreground-rgb);
    padding: 2rem 1.5rem;
    border: 2px solid var(--app-text-color);
    position: absolute;
    top: 60px;
    right: 0;
    flex-direction: column;
    width: 250px;
    display: none;

    &:hover {
      display: flex;
    }

    .navItemWarp {
      margin: 0 0 15px;
      padding: 0;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;

      &:last-child {
        margin: 0;
      }
    }
  }

  &:hover {
    .navItemText {
      color: var(--app-link-hover);
    }

    .navDropdown {
      display: flex;
    }
  }
}

@media screen and (max-width: 900px) {
  .navItemWarp {
    // display: block;

    // width: 100%;
    // box-sizing: border-box;
    justify-content: flex-start;

    // > .navItemText {
    //   font-size: 1rem;
    // }
  }
}

// @media (max-width: 950px) and (min-width: 900px) {
//   .navItemWarp {
//     width: 100%;
//     // box-sizing: border-box;
//     justify-content: flex-start;
//   }
// }

// @media (max-width: 980px) and (min-width: 900px) {
//   .navItemWarp {
//     > .navItemText {
//       font-size: 1rem;
//     }
//   }
// }

// @media (max-width: 1280px) and (min-width: 980px) {
//   .navItemWarp {
//     > .navItemText {
//       font-size: 1rem;
//     }
//   }
// }

// @media screen and (min-width: 1280px) {
//   .navItemWarp {
//     > .navItemText {
//       font-size: 1.125rem;
//     }
//   }
// }
