.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  // position: relative;

  .collectContainer {
    // position: absolute;
    // top: 10px;
    right: 200px;
    width: 200px;
    height: 50px;
    background: red;
    border-radius: 10px;
  }

  .header,
  .footer {
    position: relative;
    width: 100%;
  }

  .header {
    z-index: 999;
  }

  .main {
    background: var(--foreground-rgb);
    width: 100%;
    min-height: 50vh;
    flex: 1 1;
    margin-top: calc(var(--pietra-global-header-heigth) - 2px);
    // margin-top: 0px;
    background-color: var(--foreground-rgb);
    background-size: 100% auto;
    background-repeat: no-repeat;
    // background-image: url('/images/layouts/background-icon.webp');
    background-image: url('https://res-front.pumpsoul.com/ins-prod/image/background-icon.webp');
    // background-image: url('/images/layouts/starBgk.webp');

    &.mobile {
      margin-top: var(--pietra-global-mobile-header-heigth);
    }
  }
}

