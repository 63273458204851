.titleContainer {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
}

.formContent {
  align-items: center;
  display: flex;
  flex-direction: column;

  .desc {
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    color: var(--app-text-color);
  }

  .btnsContainer {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .bottomButton,
  .bottomCancelButton {
    flex: 1;
    min-width: 150px;
    height: 40px;
    border-radius: 20px;
    font-family: Inter, Inter;
    // font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1px;
    text-align: center;
    font-style: normal;
    text-transform: none;

    color:#FFF;
    background: linear-gradient( 180deg, #725CFF 0%, rgba(141,131,255,0.8) 100%);
  }

  .bottomCancelButton {
    background: #FFFFFF;
    border: 1px solid #999999;
    color: #0C0C0C;
  }
}

